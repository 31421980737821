<template>
  <div class="login v2">
    <div class="wrapper">
      <div class="dialog dialog-shadow" style="display: block; margin-top: -300px">
        <div class="title">
          <h4>欢迎使用五悦云·电子书</h4>
        </div>
        <div v-if="loginPage" class="content">
          <ul class="common-form">
            <li class="username border-1p">
              <div class="input">
                <input type="text" v-model="ruleForm.username" placeholder="账号" />
              </div>
            </li>
            <li>
              <div class="input">
                <input type="password" v-model="ruleForm.password" @keyup.enter="login" placeholder="密码" />
              </div>
            </li>
            <li style="text-align: right" class="pr">
              <a style="padding: 1px 0 0 10px" @click="open('找回密码', '请联系管理员找回密码')">忘记密码?</a>
            </li>
          </ul>
          <!--登陆-->
          <div style="margin-top: 25px">
            <el-button size="primary" style="width: 100%" @click="login">登 录</el-button>
          </div>
          <!--返回-->
          <div style="margin-top: 25px">
            <el-button style="width: 100%" @click="$router.go(-1)">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {login,getInfo } from "@/api"
export default {
  name: "login",
  data() {
    return {
      cart: [],
      loginPage: true,
      ruleForm: {
        username: "",
        password: "",
      
      },
     
      autoLogin: false,
      logintxt: "登录",
      statusKey: "",
    };
  },
  methods: {
    open(t, m) {
      this.$notify.info({
        title: t,
        message: m,
      });
    },
    login() {
      if (!this.ruleForm.username || !this.ruleForm.password)
        return this.$message.error({ message: "账号或者密码不能为空!" });

       
       
        login(this.ruleForm.username,this.ruleForm.password).then(res=>{
          // console.log(res);
          if(res.code === 200){
            console.log(res.token);
            this.Storage.set("token", res.token);
            getInfo().then((r) => {
              // console.log(r);
              if (r.code == 200) {
                this.Storage.set("userInfo", r.user);
                this.$message.success('登录成功');
                setTimeout(()=>{
                  this.$router.push("/");
                },500)              
              } else {
                this.$message.error(r.msg);
                this.Storage.clear();
              }
            });

          }

        })

    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  .input {
    height: 50px;
    display: flex;
    align-items: center;

    input {
      font-size: 16px;
      width: 100%;
      height: 100%;
      padding: 10px 15px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 6px;
    }
  }

  .wrapper {
    background: url("https://oss.culturalcloud.net/furong/202212/01090934cyy6.jpg") no-repeat;
    background-size: cover;
  }
}

.v2 .dialog {
  width: 450px;
  border: 1px solid #dadada;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  margin-left: -225px;
  position: absolute;

  .title {
    background: linear-gradient(#fff, #f5f5f5);
    height: auto;
    overflow: visible;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
    position: relative;
    background-image: url("https://oss.culturalcloud.net/furong/202212/08095342zx4o.png");
    // background-size: 100px;
    background-position: top center;
    background-repeat: no-repeat;
    height:120px;
    margin: 25px 0 25px;
    padding: 75px 0 0;
    box-shadow: none;

    h4 {
      padding: 0;
      text-align: center;
      color: #666;
      border-bottom: 1px solid #dcdcdc;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      font-weight: 700;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
      border-bottom: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      line-height: 1em;
      height: auto;
      color: #333;
      font-weight: 400;
    }
  }

  .content {
    padding: 0 40px 22px;
    height: auto;

    .common-form {
      li {
        clear: both;
        margin-bottom: 15px;
        position: relative;
      }
    }
  }
}

// .dialog-shadow,
// .v2 .bbs .dialog-shadow,
// .v2 .dialog-shadow {
//   -webkit-box-shadow: 0 9px 30px -6px rgba(0, 0, 0, 0.2),
//     0 18px 20px -10px rgba(0, 0, 0, 0.04), 0 18px 20px -10px rgba(0, 0, 0, 0.04),
//     0 10px 20px -10px rgba(0, 0, 0, 0.04);
//   -moz-box-shadow: 0 9px 30px -6px rgba(0, 0, 0, 0.2),
//     0 18px 20px -10px rgba(0, 0, 0, 0.04), 0 18px 20px -10px rgba(0, 0, 0, 0.04),
//     0 10px 20px -10px rgba(0, 0, 0, 0.04);
//   box-shadow: 0 9px 30px -6px rgba(0, 0, 0, 0.2),
//     0 18px 20px -10px rgba(0, 0, 0, 0.04), 0 18px 20px -10px rgba(0, 0, 0, 0.04),
//     0 10px 20px -10px rgba(0, 0, 0, 0.04);
// }

@media screen and (min-width: 737px),
screen and (-webkit-max-device-pixel-ratio: 1.9) and (max-width: 736px) and (min-device-width: 737px) {
  .wrapper {
    background: url(/static/images/con-bg_04f25dbf8e.jpg) repeat-x;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .dialog {
    background: url(/static/images/dialog-gray-bg.png) #fff bottom repeat-x;
    border-radius: 12px;
    display: none;
    margin: -163px 0 0 -218px;
    width: 436px;
    position: fixed;
    left: 50%;
    top: 50%;
  }

  .dialog .title h4 {
    border-bottom: #d1d1d1 solid 1px;
    box-shadow: 0 2px 6px #d1d1d1;
    color: #666;
    font-size: 20px;
    height: 61px;
    line-height: 61px;
    padding: 0 0 0 35px;
  }

  //   .common-form li {
  //     clear: both;
  //     margin-bottom: 15px;
  //     position: relative;
  //   }
  //   .auto-login {
  //     position: absolute;
  //     top: 0px;
  //     left: 2px;
  //     color: #999;
  //   }
  //   .register {
  //     padding: 1px 10px 0;
  //     border-right: 1px solid #ccc;
  //   }
  //   .border {
  //     margin-top: 10px;
  //     border-bottom: 1px solid #ccc;
  //   }
  //   .other {
  //     margin: 20px 5px 0 0;
  //     width: auto;
  //     color: #bbb;
  //     font-size: 12px;
  //     cursor: default;
  //     color: #999;
  //   }
  //   .footer {
  //     display: flex;
  //     flex-direction: row;
  //   }
  //   .agree {
  //     margin-bottom: 30px;
  //     color: #999;
  //   }
}

// .registered {
//   h4 {
//     padding: 0;
//     text-align: center;
//     color: #666;
//     border-bottom: 1px solid #dcdcdc;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
//     font-weight: 700;
//     font-size: 20px;
//     height: 60px;
//     line-height: 60px;
//   }
// }

// #wait {
//   text-align: left;
//   color: #999;
//   margin: 0;
// }
</style>